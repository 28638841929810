import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// module imports
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// directive imports

// layout component imports
import { LayoutDefaultComponent } from '../layouts/layout-default/layout-default.component';

// component imports
import { HeaderComponent } from '../components/header/header.component';
import { ToastComponent } from '../components/toast/toast.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { FooterMinimalComponent } from '../components/footer-minimal/footer-minimal.component';

// form component imports

// container

// add layout components here
const LAYOUTS: any = [LayoutDefaultComponent];

// add created components here
const COMPONENTS: any = [HeaderComponent, ToastComponent, LoaderComponent];

// add created form components here
const FORM_COMPONENTS: any = [FooterMinimalComponent];

// add created container components here
const CONTAINER_COMPONENTS: any = [];

// add pipes here
const PIPES: any = [];

// add directives here
const DIRECTIVES: any = [];

// add module or component exports here
const COMPONENT_EXPORTS: any = [NgbTooltipModule];

// do not modify these two unless necessary
const APP_DECLARATIONS: any = [].concat(
  PIPES,
  DIRECTIVES,
  LAYOUTS,
  COMPONENTS,
  FORM_COMPONENTS,
  CONTAINER_COMPONENTS
);

const APP_DECLARATION_EXPORTS: any = [].concat(
  APP_DECLARATIONS,
  COMPONENT_EXPORTS
);

@NgModule({
  declarations: [APP_DECLARATIONS],
  imports: [
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
  ],
  exports: [APP_DECLARATION_EXPORTS],
  providers: [],
})
export class SharedModule {}
