<header class="header">
  <div class="header__container">
    <div class="header__logo">
      <a routerLink="/">
        <img
          class="header__logo-img"
          src="/assets/public/images/common/logos/musictribe.svg"
          alt=""
        />
      </a>
    </div>
  </div>
</header>
