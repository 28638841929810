import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
  @Input() loaderType: string = 'default'; // Can be set as 'default' or 'small'
  ngOnInit(): void {}
}
